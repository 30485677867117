import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import {
  loginInfoAtom,
  modalInfoAtom,
  refundInfoAtom,
  withdrawalInfoAtom,
} from 'store/globalStateAtom';

import useNotwithdrawal from 'hooks/useNotwithdrawal';
import Layout from 'components/common/Layout';
import EmailKeypad from 'components/common/EmailKeypad';
import LoadingView from 'components/common/LoadingView';
import alert from 'images/common/alert_round.png';

function Notwithdrawal() {
  const { t } = useTranslation();
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [refundInfo] = useAtom(refundInfoAtom);
  const [withdrawalInfo, setWithdrawalInfo] = useAtom(withdrawalInfoAtom);
  const [userEmail, setUserEmail] = useState('');
  const { isLoading, mutate } = useNotwithdrawal();

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setUserEmail(e.target.value);
  };

  /** 이메일 입력 후 확인 클릭 */
  const onClick = () => {
    setWithdrawalInfo({
      ...withdrawalInfo,
      notwithdrawal: withdrawalInfo.totalRefund - withdrawalInfo.withdrawal, // 미방출금액
    });
    setModalInfo({
      title: userEmail,
      description: t('notwithdrawal_email_check_desc'),
      icon: 'ALERT',
      btnText: t('notwithdrawal_re_enter'),
      subBtnText: t('modal_confirm'),
      btnClass: 'bg-mono-10 text-mono-80 active:bg-mono-20', // 왼쪽 버튼
      subBtnClass: 'bg-blue-100 text-white active:bg-blue-150', // 오른쪽 버튼
      btnCallback: () => {},
      subBtnCallback: () => {
        mutate({
          refundIdList: refundInfo.refundIndexList,
          controlCode: loginInfo.businessNumber,
          passportNumber: withdrawalInfo.passportNumber,
          email: userEmail,
          notWithdrawAmount:
            withdrawalInfo.totalRefund - withdrawalInfo.withdrawal,
        });
      },
    });
  };

  return (
    <Layout isSetting>
      {isLoading && <LoadingView />}
      <div>
        <img src={alert} width={120} alt="alert_icon" className="mx-auto" />
      </div>
      <div className="h-72">
        <h2 className="text-head-01 text-mono-80 font-bold mt-10 text-center whitespace-pre-line">
          {t('notwithdrawal_title')}
        </h2>
        <p className="text-subHead-01 font-semibold mt-3 text-mono-50 whitespace-pre-wrap text-center">
          {t('notwithdrawal_desc')}
        </p>
      </div>
      <h2 className="text-head-01 text-mono-80 font-bold mt-16">
        {t('notwithdrawal_email_label')}
      </h2>
      <input
        className="py-8 px-12 rounded-20 placeholder:text-[42px] text-[42px] w-full mt-16 outline outline-4 outline-blue-60"
        placeholder="ktp@taxfree.com"
        onChange={onChange}
        value={userEmail}
      />
      <div className="flex justify-center mt-80 mb-2">
        <button
          className="text-subHead-01 flex items-center disabled:bg-blue-20 bg-blue-100 active:bg-blue-150 text-white py-6 px-60 rounded-100 font-semibold mt-36 shadow-default"
          onClick={onClick}
        >
          {t('modal_confirm')}
        </button>
      </div>
      <EmailKeypad setInput={setUserEmail} containerStyle="mt-4" />
    </Layout>
  );
}

export default Notwithdrawal;
