import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAtom } from 'jotai';

import Layout from 'components/common/Layout';
import ErrorStatus from 'components/Setting/ErrorStatus';
import CurrentCashAmount from 'components/Setting/CurrentCashAmount';
import ControlCodeFormModal from 'components/Setting/ControlCodeFormModal';
import { adminLoginInfoAtom, loginInfoAtom } from 'store/globalStateAtom';

type Props = {
  resetKiosk: () => void;
  isLoading: boolean;
};
function Setting({ resetKiosk, isLoading }: Props) {
  const navigate = useNavigate();
  const [loginInfo] = useAtom(loginInfoAtom);
  const [adminLoginInfo, setAdminLoginInfo] = useAtom(adminLoginInfoAtom);

  const onClose = () => {
    navigate(-1);
    setAdminLoginInfo({ manager: null, controlCode: null });
  };

  useEffect(() => {
    const handleTouchMove = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => document.removeEventListener('touchmove', handleTouchMove);
  }, []);

  return (
    <Layout isLoading={isLoading}>
      <ControlCodeFormModal />
      <h2 className='text-head-01 font-bold text-mono-80'>
        {loginInfo?.hospitalName} 환급 키오스크
      </h2>
      <ErrorStatus resetKiosk={resetKiosk} />
      {adminLoginInfo?.manager && <CurrentCashAmount />}
      <button
        onClick={onClose}
        className='shadow-default rounded-100 bg-white py-5 px-72 flex text-mono-80 text-body-01 font-semibold items-center gap-2 mx-auto mt-[400px] mb-10'
      >
        닫기
      </button>
      <p className='text-mono-65 text-body-02 text-center'>
        KTP 고객센터 : 02-6275-8011
      </p>
    </Layout>
  );
}

export default Setting;
