import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import Layout from 'components/common/Layout';
import QwertyKeypad from 'components/common/QwertyKeypad';
import passport from 'images/PassportCertification/passport.png';
import progressKo from 'images/PassportCertification/progress_ko_1.png';
import progressEn from 'images/PassportCertification/progress_en_1.png';
import progressCh from 'images/PassportCertification/progress_ch_1.png';
import progressJp from 'images/PassportCertification/progress_jp_1.png';
import arrowLeft from 'images/common/chevron_left_black.png';
import arrowRight from 'images/common/chevron_right_white.png';
import useGetRefundInfo from 'hooks/useGetRefundInfo';
import {
  emissionKioskStatusAtom,
  kioskStatusAtom,
  languageAtom,
  loginInfoAtom,
  withdrawalInfoAtom,
} from 'store/globalStateAtom';
import useLogging from 'hooks/useLogging';

function PassportCertification() {
  const { t } = useTranslation();
  const [lang] = useAtom(languageAtom);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [withdrawalInfo, setWithdrawalInfo] = useAtom(withdrawalInfoAtom);
  const [emissionKioskStatus, setEmissionKioskStatus] = useAtom(
    emissionKioskStatusAtom
  );
  const [kioskStatus] = useAtom(kioskStatusAtom);
  const [passportNumber, setPassportNumber] = useState('');
  const navigate = useNavigate();
  const { mutate, isLoading } = useGetRefundInfo();
  const { mutate: logOnServer } = useLogging();

  const formatInputValue = (value: string) => value.trim().toUpperCase();

  const log = (data: string) => {
    logOnServer({ businessNumber: loginInfo?.businessNumber, data });
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const formattedValue = formatInputValue(e.target.value);
    setPassportNumber(formattedValue);
  };
  const goBack = () => {
    navigate('/language-selection');
  };
  const goNext = async () => {
    const { bd1Error, bd2Error, bd3Error, hp1Error } = kioskStatus;
    // 키오스크 교차방출 상태 확인 에러가 있으면 false
    await setEmissionKioskStatus({ bd2Error, bd3Error });
    // 동기를 사용한 이유 -> withdrawalInfo를 바로 사용하기 위해
    await setWithdrawalInfo({
      passportNumber: passportNumber,
      totalRefund: 0,
      withdrawal: 0,
      notwithdrawal: 0,
    });

    log(
      `키오스크 여권 조회 :: - 키오스크 이름: ${loginInfo?.hospitalName}, - passportNumber: ${passportNumber}`
    );

    // 여권정보를 입력할 때 방출관련 정보 초기화
    mutate({ passportNumber, businessNumber: loginInfo.businessNumber });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/promotion');
    }, 60000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout isLoading={isLoading} isSetting>
      <img
        src={
          lang === 'en'
            ? progressEn
            : lang === 'jp'
            ? progressJp
            : lang === 'ch'
            ? progressCh
            : progressKo
        }
        height={90}
        width={960}
        alt="progress"
        className="mb-16 w-full"
      />
      <div className="w-full rounded-20 bg-mono-05 p-11">
        <img
          src={passport}
          height={410}
          width={600}
          alt="passport"
          className="mx-auto"
        />
      </div>
      <h2 className="text-head-01 text-mono-80 font-bold mt-16">
        {t('enter_passport')}
      </h2>
      <input
        className="py-8 px-12 rounded-20 placeholder:text-[42px] text-[42px] w-full mt-16 outline outline-4 outline-blue-60"
        placeholder="Passport NO."
        onChange={onChange}
        value={passportNumber}
      />
      <div className="flex justify-between">
        <button
          onClick={goBack}
          className="text-subHead-01 flex items-center bg-white py-6 px-28 rounded-100 text-mono-80 font-semibold mt-36 shadow-default"
        >
          <img src={arrowLeft} width={36} height={36} alt="arrow_left" />
          {t('prev_step')}
        </button>
        <button
          onClick={goNext}
          disabled={!passportNumber}
          className="text-subHead-01 flex items-center disabled:bg-blue-20 bg-blue-100 active:bg-blue-150 text-white py-6 px-28 rounded-100 font-semibold mt-36 shadow-default"
        >
          {t('next_step')}
          <img src={arrowRight} width={36} height={36} alt="arrow_right" />
        </button>
      </div>
      <QwertyKeypad setInput={setPassportNumber} containerStyle="mt-4" />
    </Layout>
  );
}

export default PassportCertification;
