import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAtom } from 'jotai';

import Layout from 'components/common/Layout';
import LoadingView from 'components/common/LoadingView';
import NumberKeypad from 'components/common/NumberKeypad';
import ChargingItem from 'components/CashCharging/ChargingItem';
import arrowLeft from 'images/common/chevron_left_black.png';
import check from 'images/common/check.png';
import {
  adminLoginInfoAtom,
  billTypeAtom,
  KioskStatus,
  kioskStatusAtom,
  loginInfoAtom,
  modalInfoAtom,
} from 'store/globalStateAtom';
import useUpdateKioskStatus from 'hooks/useUpdateKioskStatus';
import { findProperty } from 'utils';

export type ActiveInput =
  | ''
  | 'hp1Count'
  | 'bd1Count'
  | 'bd2Count'
  | 'bd3Count';
export type BillCalculateType = {
  hp1: CalculateType;
  bd1: CalculateType;
  bd2: CalculateType;
  bd3: CalculateType;
};
export type CalculateType = '+' | '-';
function CashCharging() {
  const navigate = useNavigate();
  const [loginInfo] = useAtom(loginInfoAtom);
  const [billType] = useAtom(billTypeAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [kioskStatus, setKioskStatus] = useAtom(kioskStatusAtom);
  const [adminLoginInfo] = useAtom(adminLoginInfoAtom);
  const [activeInput, setActiveInput] = useState<ActiveInput>('');

  const { bd1TotalCount, bd2TotalCount, bd3TotalCount, hp1TotalCount } =
    kioskStatus as KioskStatus;
  const { mutate, isLoading, isSuccess } = useUpdateKioskStatus();
  const [inputValues, setInputValues] = useState({
    hp1Count: '',
    bd1Count: '',
    bd2Count: '',
    bd3Count: '',
  });
  const [calculateType, setCalculateType] = useState<BillCalculateType>({
    hp1: '+',
    bd1: '+',
    bd2: '+',
    bd3: '+',
  });
  const { hp1Count, bd1Count, bd2Count, bd3Count } = inputValues;

  const onSave = () => {
    const newKioskStatus = {
      ...kioskStatus,
    };

    const updateTotalCount = (
      totalCount: number,
      count: string,
      operation: CalculateType
    ) =>
      operation === '+'
        ? totalCount + Number(count)
        : totalCount - Number(count);

    Object.entries(calculateType).forEach(([key, value]) => {
      switch (key) {
        case 'hp1':
          newKioskStatus.hp1TotalCount = updateTotalCount(
            kioskStatus.hp1TotalCount,
            hp1Count,
            value
          );
          break;
        case 'bd1':
          newKioskStatus.bd1TotalCount = updateTotalCount(
            kioskStatus.bd1TotalCount,
            bd1Count,
            value
          );
          break;
        case 'bd2':
          newKioskStatus.bd2TotalCount = updateTotalCount(
            kioskStatus.bd2TotalCount,
            bd2Count,
            value
          );
          break;
        case 'bd3':
          newKioskStatus.bd3TotalCount = updateTotalCount(
            kioskStatus.bd3TotalCount,
            bd3Count,
            value
          );
          break;
      }
    });
    mutate({
      type: 'DEPOSIT',
      controlCode: adminLoginInfo.controlCode,
      businessNumber: loginInfo.businessNumber,
      ...newKioskStatus,
    });
    setKioskStatus(newKioskStatus);
  };

  const onKeyPress = (key: any) => {
    if (activeInput === '') {
      return;
    }
    if (key === 'Back') {
      setInputValues((prev) => ({
        ...prev,
        [activeInput]: prev[activeInput].slice(0, -1),
      }));
      return;
    }
    if (key === 'Clear') {
      setInputValues((prev) => ({
        ...prev,
        [activeInput]: '',
      }));
      return;
    }

    setInputValues((prev) => ({
      ...prev,
      [activeInput]: prev[activeInput] + key,
    }));
  };

  const onCancel = () => {
    setModalInfo({
      title: '현금 보충을 취소할까요?',
      description: `취소하기를 누르면 보충 내역은 저장되지 않습니다.\n현금 보충을 계속하려면 닫기를 눌러주세요.`,
      icon: 'ALERT',
      btnText: '충전 계속하기',
      subBtnText: '취소하기',
      subBtnCallback: () => navigate(-1),
    });
  };

  const setMaximumOrMinumumIfOverflowing = () => {
    const setValue = (
      activeInput: string,
      totalCount: number,
      limit?: number
    ) => {
      if (limit) {
        setInputValues((prev) => ({
          ...prev,
          [activeInput]: String(limit - totalCount),
        }));
      } else {
        setInputValues((prev) => ({
          ...prev,
          [activeInput]: String(totalCount),
        }));
      }
    };
    switch (activeInput) {
      case 'hp1Count':
        if (+hp1Count > 300 - hp1TotalCount && calculateType.hp1 === '+') {
          setValue(activeInput, hp1TotalCount, 300);
        }
        if (+hp1Count > 300 + hp1TotalCount && calculateType.hp1 === '-') {
          setValue(activeInput, hp1TotalCount);
        }
        break;

      case 'bd1Count':
        if (+bd1Count > 2000 - bd1TotalCount && calculateType.bd1 === '+') {
          setValue(activeInput, bd1TotalCount, 2000);
        }
        if (+bd1Count > 2000 + bd1TotalCount && calculateType.bd1 === '-') {
          setValue(activeInput, bd1TotalCount);
        }
        break;
      case 'bd2Count':
        if (+bd2Count > 2000 - bd2TotalCount && calculateType.bd2 === '+') {
          setValue(activeInput, bd2TotalCount, 2000);
        }
        if (+bd2Count > 2000 + bd2TotalCount && calculateType.bd2 === '-') {
          setValue(activeInput, bd2TotalCount);
        }
        break;
      case 'bd3Count':
        if (+bd3Count > 2000 - bd3TotalCount && calculateType.bd3 === '+') {
          setValue(activeInput, bd3TotalCount, 2000);
        }
        if (+bd3Count > 2000 + bd3TotalCount && calculateType.bd3 === '-') {
          setValue(activeInput, bd3TotalCount);
        }
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (!isSuccess) return;
    navigate(-1);
  }, [isSuccess]);

  useEffect(() => {
    setMaximumOrMinumumIfOverflowing();
  }, [inputValues, calculateType]);
  return (
    <Layout>
      {isLoading && <LoadingView />}
      <h2 className="text-mono-80 text-head-01 font-bold mt-30 mb-20">
        현금 보충
      </h2>
      <div className="flex flex-col gap-5">
        {Object.entries(billType).map(([type, value]) => {
          const activeBillType = Object.keys(billType).filter(
            (key) => billType[key] !== null
          );
          const isActiveBillType = activeBillType.includes(type);
          return (
            <ChargingItem
              key={type}
              //@ts-ignore
              billUnit={value}
              type={type}
              //@ts-ignore
              targetCount={type && (type === 'hp1' ? 300 : 2000)}
              currentCount={type && kioskStatus[type + 'TotalCount']}
              //@ts-ignore
              value={type ? inputValues[type + 'Count'] : ''}
              active={isActiveBillType ? activeInput === type : false}
              setActive={setActiveInput}
              calculateType={calculateType}
              setCalculateType={setCalculateType}
              disabled={!isActiveBillType}
            />
          );
        })}
      </div>
      <div className="flex justify-between">
        <button
          onClick={onCancel}
          className="text-subHead-01 flex items-center bg-white py-6 px-28 rounded-100 text-mono-80 font-semibold mt-36 shadow-default"
        >
          <img src={arrowLeft} width={36} height={36} alt="arrow_left" />
          취소
        </button>
        <button
          onClick={onSave}
          disabled={!(bd1Count || bd2Count || hp1Count || bd3Count)}
          className="text-subHead-01 flex items-center disabled:bg-blue-20 bg-blue-100 active:bg-blue-150 text-white py-6 px-28 rounded-100 font-semibold mt-36 shadow-default"
        >
          <img src={check} width={36} height={36} alt="arrow_right" />
          저장하기
        </button>
      </div>
      <NumberKeypad onKeyPress={onKeyPress} containerStyle="mt-20" />
    </Layout>
  );
}

export default CashCharging;
